<template>
  <div class="container py-5">
    <b-row class="mb-5">
      <b-col cols="12">
        <b-form inline>
          <label class="sr-only" for="inline-form-input-name">Search</label>
          <b-form-input
            id="prodSearch"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Search Products"
            v-model="searchQuery"
          />
          <b-button variant="outline-secondary">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card-group columns>
          <div v-for="item in filteredItems" :key="item.id">
            <router-link :to="'/item/' + item.id">
              <b-card
                :title="item.title"
                :img-src="require('../assets/' + item.img)"
                img-alt="Image"
                tag="article"
                class="mb-4"
                align="center"
                title-tag="p"
              >
                <b-card-text> By {{ item.manufacture }} </b-card-text>
              </b-card>
            </router-link>
          </div>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import db from "../db.js";

export default {
  name: "items",
  data: function() {
    return {
      items: [],
      searchQuery: ""
    };
  },
  computed: {
    filteredItems: function() {
      const dataFilter = item =>
        item.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
        (item.manufacture.toLowerCase().match(this.searchQuery.toLowerCase()) &&
          true);
      return this.items.filter(dataFilter);
    }
  },
  mounted() {
    db.collection("items").onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        this.items.push({
          id: doc.id,
          title: doc.data().title,
          body: doc.data().title,
          img: doc.data().img,
          manufacture: doc.data().manufacture
        });
      });
    });
  }
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
.card {
  font-weight: 100;
}
.card-text {
  color: black;
}
.card-title {
  font-weight: 100;
  min-height: 50px;
}
.card-img {
  padding: 5%;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
