<template>
  <div>
    <Navigation />
    <router-view id="rview" />
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    Navigation,
    Footer
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
#rview {
  min-height: 75vh;
}
</style>
