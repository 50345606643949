<template>
  <b-container class="py-5 mb-5">
    <b-row>
      <b-col>
        <b-img
          :src="require('../assets/' + item.img)"
          fluid
          center
          class="prodImg"
        ></b-img>
      </b-col>
      <b-col>
        <b-img
          :src="require('../assets/' + item.img2)"
          fluid
          center
          class="prodImg"
        ></b-img>
      </b-col>
    </b-row>
    <hr class="my-5" />
    <b-row>
      <b-col sm="12">
        <h2>{{ item.title }}</h2>
        <h5 class="mb-4">By {{ item.manufacture }}</h5>
        <p v-html="item.body"></p>
      </b-col>
    </b-row>
    <hr class="my-5" />
    <b-row>
      <b-col>
        <p>
          <a :href="item.link" target="_blank"
            >More information from {{ item.manufacture }}.</a
          >
        </p>
        <p>
          Contact <a href="mailto: ray@supply.vet"> Ray Hamby </a> for pricing
          information.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import db from "../db.js";

export default {
  name: "item",
  data: function() {
    return {
      item: {}
    };
  },
  mounted() {
    db.collection("items")
      .doc(this.$route.params.itemId)
      .get()
      .then(snapshot => {
        this.item = snapshot.data();
      });
  }
};
</script>

<style scoped>
.prodImg {
  max-height: 30vh;
}
</style>
