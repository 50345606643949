<template>
  <footer class="py-5 small" id="pageFooter">
    <b-container>
      <b-row>
        <b-col lg="4">
          <address>
            <a
              href="http://maps.google.com/?q=1 Perimeter Park South 100N, Birmingham, AL, 35243"
              target="_blank"
            >
              Veterans Specialty Supply + Logistics <br />
              1 Perimeter Park South 100N Rm. 149 <br />
              Birmingham AL 35243 U.S.A.
            </a>
          </address>
        </b-col>
        <b-col lg="8">
          <iframe
            class="gmaps"
            frameborder="0"
            style="border:0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD6s_rn_yCwBdAnBBd9_k3_7JVvKzeD5_g&q=1+Perimeter+Park+South+100N+Birmingham+AL+35243"
            allowfullscreen
          ></iframe>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style>
footer {
  bottom: 0;
  width: 100%;
  min-height: 25vh;
  color: white;
  background-color: rgb(51, 51, 51);
}
.gmaps {
  height: 30vh;
  width: 50vw;
}
address * {
  color: white !important;
}
</style>
