<template>
  <b-navbar
    sticky
    toggleable="lg"
    type="dark"
    variant="secondary"
    class="small"
  >
    <b-container>
      <b-navbar-brand @click="scrollToTop">
        <img src="../assets/logo.png" class="img-fluid" alt="logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/items">Products</b-nav-item>
          <b-nav-item to="/resources">Resources</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <a @click="scrollToBottom" class="btn btn-sm btn-info">Contact</a>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<style scoped lang="scss">
.navbar-brand img {
  height: 30px;
}
</style>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, 999999999);
    }
  }
};
</script>
