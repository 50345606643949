import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD6s_rn_yCwBdAnBBd9_k3_7JVvKzeD5_g",
  authDomain: "v-715e9.firebaseapp.com",
  databaseURL: "https://v-715e9.firebaseio.com",
  projectId: "v-715e9",
  storageBucket: "v-715e9.appspot.com",
  messagingSenderId: "540471753586",
  appId: "1:540471753586:web:b3e53b90ecda8d4c8fbbeb",
  measurementId: "G-VG1S73T1XW"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();
